<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">招工采集H5</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
<!--            <div title="姓名" class="searchboxItem ci-full">-->
<!--              <span class="itemLabel">姓名:</span>-->
<!--              <el-input-->
<!--                  v-model="courseName"-->
<!--                  type="text"-->
<!--                  size="small"-->
<!--                  clearable-->
<!--                  placeholder="请输入姓名"-->
<!--              />-->
<!--            </div>-->
<!--            <div title="联系电话" class="searchboxItem ci-full">-->
<!--              <span class="itemLabel">联系电话:</span>-->
<!--              <el-input-->
<!--                  v-model="courseName"-->
<!--                  type="text"-->
<!--                  size="small"-->
<!--                  clearable-->
<!--                  placeholder="请输入联系电话"-->
<!--              />-->
<!--            </div>-->
<!--            <el-button-->
<!--                style="margin-left: 20px"-->
<!--                class="bgc-bv"-->
<!--                round-->
<!--                @click="getData()"-->
<!--            >查询</el-button-->
<!--            >-->
            <div class="btnBox" style="margin-left: 20px">
              <el-button class="bgc-bv" round @click="handleCreate('add')"
              >新增H5页面</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  width="100px"
                  :index="indexMethod"
              />

              <el-table-column
                  label="姓名"
                  align="center"
                  prop="userName"
                  show-overflow-tooltip
                  min-width="150"
              />
              <el-table-column
                  label="联系电话"
                  align="center"
                  prop="mobile"
                  show-overflow-tooltip
                  min-width="150"
              />
              <el-table-column
                  label="二维码"
                  align="center"
                  prop="dimensionalBarcode"
                  show-overflow-tooltip
                  min-width="150"
              >
                <template slot-scope="{ row }">
                  <img :src="row.dimensionalBarcode" alt="" width="100" height="100">
                </template>
              </el-table-column>


              <el-table-column
                  label="操作"
                  align="center"
                  width="260px"
                  fixed="right"
              >
                <template slot-scope="{row}">
                  <el-popover
                      placement="left-start"
                      width="200"
                      trigger="hover"
                      :content="pageUrl+row.contactId"
                  >
                    <el-button type="text" slot="reference">查看链接</el-button>
                  </el-popover>
                  <el-button
                      type="text"
                      size="mini"
                      style="padding: 7px 10px"
                      @click="payOrder(row)"
                  >查看分享码</el-button>
                  <el-button
                      type="text"
                      size="mini"
                      @click.stop="() => handleCreate('edit', row.contactId)"
                  >编辑</el-button>

<!--                  <el-button-->
<!--                      type="text"-->
<!--                      size="mini"-->
<!--                      style="padding: 7px 10px"-->
<!--                      :disabled="scope.row.allowDeleteState"-->
<!--                      @click="deleteCourse(scope.row)"-->
<!--                  >删除</el-button-->
<!--                  >-->
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
        />
      </div>
    </div>
    <el-dialog
        width="500px"
        :title="innerTitle"
        :visible.sync="innerVisible"
    >
      <div class="qrcode">
        <!-- 放置二维码的容器,需要给一个ref -->
        <div id="qrcode" ref="qrcode"></div>
      </div>
    </el-dialog>

  </div>
</template>
<script>

import QRCode from "qrcodejs2"; // 引入qrcode
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
// import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "teacherList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      pageUrl: 'https://anzhi.bjsrxx.com/banner/app_static/hiring.html?contactId=',//招工采集H5线上地址
      // pageUrl: 'http://192.168.100.94/hiring.html?contactId=',//招工采集H5测试地址
      innerVisible: false,
      qrcode:'',
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  created() {
    this.getTableHeight();
    // this.getcourseTypeArrData();
  },
  methods: {
    // 展示二维码
    payOrder(row) {
      this.innerVisible = true;
      this.innerTitle = row.userName+'的页面分享码';
      // this.projectId = projectId;
      this.qrcode = this.pageUrl+row.contactId;
      // 使用$nextTick确保数据渲染
      this.$nextTick(() => {
        this.crateQrcode();
      });
    },
    // 生成二维码
    crateQrcode() {
      this.$refs.qrcode.innerHTML = "";
      this.qr = new QRCode("qrcode", {
        width: 300,
        height: 300, // 高度
        text: this.qrcode, // 二维码内容
        render: "canvas", // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
      });
      // console.log(this.qrcode)
    },

    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        // courseName: this.courseName
      };
      this.doFetch(
          {
            url: "/biz/recruitment/contact/pageList",
            params,
            pageNum,
          },
          true,
          // 2
      );
    },
    //新增
    handleCreate(stu, contactId = "") {
      this.$router.push({
        path: "/web/workOrder/createRecruitmentCollectionH5",
        query: {contactId, stu},
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
          window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 0.675 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },

    // 删除精品课
    // deleteCourse(row){
    //   const {courseId}=row;
    //   this.$confirm('此操作将永久删除该课程, 是否继续?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     confirmButtonClass:"confirmButton-feifan",
    //     type: 'warning'
    //   }).then(async () => {
    //     await this.$post("/minicourse/deleteCourse",{courseId},3000,true,2);
    //     this.getData(-1);
    //     this.$message.success("操作成功");
    //   })
    // },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh == "true") {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.pageNum = 1), this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style>
#qrcode img{
  margin: 0 auto;
}
</style>
<style lang="less" scoped></style>
